<template>
	<v-row v-if="visualisation">

		<v-col>
			<p class="text-right mb-2">
				<v-btn icon outlined class="ml-1" @click="deleting = true"
					><v-icon>mdi-close</v-icon></v-btn
				>
				<v-btn icon outlined class="ml-1" @click="moveVis('up')"
					><v-icon>mdi-arrow-up</v-icon></v-btn
				>
				<v-btn icon outlined class="ml-1" @click="moveVis('down')"
					><v-icon>mdi-arrow-down</v-icon></v-btn
				>
			</p>
			<v-text-field
				hide-details
				outlined
				class="mb-2"
				label="Add Title"
				v-model="visualisation.title"
				@blur="save"
			></v-text-field>

			<v-textarea
				hide-details
				dense
				outlined
				v-model="visualisation.notes"
				label="Notes"
				@blur="save"
			></v-textarea>
		</v-col>
		<v-col cols="9">
			<v-card flat class="mb-2" v-if="visualisation">
				<v-card-text>
					<Chart :id="id"
						v-if="visualisation.type == 'chart'"
						:type="visualisation.chart_type"
						v-bind="visualisation.chart_data"
						:options="visualisation.chart_options"
					></Chart>
					<v-data-table
						:id="id"
						v-else
						:items="visualisation.table_rows"
						:headers="visualisation.table_columns"
					></v-data-table>
				</v-card-text>
				<are-you-sure
					:value="deleting"
					@input="deleteVis"
					@cancel="deleting = false"
					text="Are you sure that you want to remove this visualisation from the report"
				></are-you-sure>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import Chart from "@c/results/analysis/Chart";
import AreYouSure from "@c/notices/AreYouSure.vue";

export default {
	name: "RenderVisualisation",
	props: {
		id: { type: String },
		canSave: {type: Boolean}
	},
	data: () => {
		return {
			deleting: false,
		};
	},
	computed: {
		visualisation() {
			return this.$store.state.visualisations.data[this.id];
		},
	},
	components: {
		Chart,
		AreYouSure,
	},
	methods: {
		save() {
			if( !this.canSave ){
				return;
			}
			this.$store.dispatch("visualisations/patch", {
				id: this.id,
				title: this.visualisation.title || "",
				notes: this.visualisation.notes || "",
			});
		},

		deleteVis() {

			this.$emit("deleteVis");
			this.deleting = false;
		},
		moveVis(direction) {
			this.$emit("moveVis", direction);
		},
	},
};
</script>
