<template>
	<v-dialog v-model="dialog" fullscreen>
		<template v-slot:activator="{ on }">
			<v-btn icon v-on="on"><v-icon>mdi-printer</v-icon></v-btn>
		</template>
		<v-card>
			<v-card-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="print" class="mr-2">
					<v-icon>mdi-download</v-icon>
				</v-btn>
				<v-btn icon @click="dialog = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text>
				<vue-html2pdf
					:show-layout="false"
					:float-layout="false"
					:enable-download="true"
					:preview-modal="false"
					:paginate-elements-by-height="1400"
					filename="report"
					:manual-pagination="false"
					pdf-format="a4"
					ref="html2Pdf"
				>
					<section slot="pdf-content" style="margin: 30px; font-family: Helvetica, sans-serif">
						<h2>{{ report.name }}</h2>
						<p>{{ report.introduction }}</p>

						<template v-for="id in report.visualisations">
							<div :key="id" v-if="visualisations[id]" style="margin-bottom: 36px;">
								<h4>{{ visualisations[id].title }}</h4>
								<p>{{ visualisations[id].notes }}</p>
                                <div style="margin-top: 14px">

								<Chart
									v-if="visualisations[id].type == 'chart'"
									:type="visualisations[id].chart_type"
									v-bind="visualisations[id].chart_data"
									:options="visualisations[id].chart_options"
								></Chart>
								<v-data-table
									v-else
									:items="visualisations[id].table_rows"
									:headers="visualisations[id].table_columns"
								></v-data-table>
                                </div>
							</div>
						</template>
					</section>
				</vue-html2pdf>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Chart from "@c/results/analysis/Chart";
import VueHtml2pdf from "vue-html2pdf";

export default {
	name: "PrintReport",
	props: {
		id: { type: String },
	},
	data: () => ({
		dialog: false,
	}),
	computed: {
		report() {
			return this.$store.state.reports.data[this.id] || {};
		},
		visualisations() {
			return this.$store.state.visualisations.data;
		},
	},
	components: {
		Chart,
		VueHtml2pdf,
	},
	methods: {
		print() {
			this.$refs.html2Pdf.generatePdf();
		},
	},
};
</script>
