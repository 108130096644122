<template>
	<v-sheet flat>
		<v-card flat v-if="canAccess">
			<v-card-title>
				<v-text-field
					label="Title"
					v-model="report.name"
					outlined
					@blur="save"
					hide-details
				></v-text-field>
				<v-spacer></v-spacer>
				<v-btn v-if="canSave" icon @click="dialog = true"
					><v-icon>mdi-account-plus</v-icon></v-btn
				>
				<print-report :id="id"></print-report>
			</v-card-title>
			<mw-dialog
				:value="dialog"
				@input="colleague = null"
				submitBtn
				title="Share Report"
				@submit="addColleague"
			>
				<template v-slot:dialog-body>
					Give user permission then share link
					<add-colleague v-model="colleague"></add-colleague>

					<v-alert color="lightest" class="pa-3 mt-5">
						<span class="grey--text">{{ link }}</span>
						<copy-button :text="link"></copy-button>
					</v-alert>
				</template>
			</mw-dialog>
			<v-textarea
				class="pa-4"
				label="Introduction"
				v-model="report.introduction"
				@blur="save"
				outlined
			></v-textarea>
			<report-visualisation
				v-for="vis in report.visualisations"
				:canSave="canSave"
				:key="vis"
				:id="vis"
				@deleteVis="deleteVis(vis)"
				@moveVis="(direction) => moveVis(direction, vis)"
			>
			</report-visualisation>
		</v-card>
		<v-alert color="primary" text v-else>
			You do not have permission to access this report
		</v-alert>
	</v-sheet>
</template>

<script>
import ReportVisualisation from "@c/results/report-builder/ReportVisualisation";
import { arrayUnion, arrayRemove } from "vuex-easy-firestore";
import AddColleague from "@c/collaboration/AddColleague";
import CopyButton from "@c/buttons/CopyButton.vue";
import MwDialog from "@c/ui/MwDialog.vue";
import PrintReport from "@c/results/report-builder/PrintReport.vue";

export default {
	name: "RenderReport",
	props: {
		id: { type: String },
	},
	data: () => {
		return {
			dialog: false,
			colleague: "",
		};
	},
	computed: {
		auth() {
			return this.$store.state.auth.data.id;
		},
		canAccess() {
			if( !this.report ){
				return false;
			}
			return this.report.users.includes(this.auth);
		},
		link() {
			return `${window.location.origin}/report/${this.id}`;
		},
		report() {
			return this.$store.state.reports.data[this.id];
		},
		canSave() {
			return this.auth == this.report.created_by;
		},
	},
	components: {
		AddColleague,
		MwDialog,
		CopyButton,
		ReportVisualisation,
		PrintReport,
	},
	methods: {
		addColleague() {
			let colleague = this.colleague;
			this.$store
				.dispatch("reports/patch", {
					id: this.id,
					users: arrayUnion(colleague),
				})
				.then(() => {
					this.colleague = null;
					this.dialog = false;
				});
		},
		save() {
			if (!this.canSave) {
				return;
			}

			this.$store.dispatch("reports/patch", {
				id: this.id,
				name: this.report.name,
				introduction: this.report.introduction,
			});
		},
		deleteVis(id) {
			if (!this.canSave) {
				this.report.visualisations = this.report.visualisations.filter(
					(i) => i !== id
				);
			}
			this.$store.dispatch("reports/patch", {
				id: this.id,
				visualisations: arrayRemove(id),
			});
		},
		moveVis(direction, id) {
			let visualisations = this.report.visualisations;
			this.mwutils.moveItemInArray(id, visualisations, direction);
			if (this.canSave) {
				this.$store.dispatch("reports/patch", {
					id: this.id,
					visualisations,
				});
			}
		},
	},
	created() {
		this.$store.dispatch("reports/fetchById", this.id);
	},
};
</script>
